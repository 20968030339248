const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

const TimeInMilliseconds = {
  SECOND: second,
  MINUTE: minute,
  HOUR: hour,
  DAY: day,
};
export default TimeInMilliseconds;

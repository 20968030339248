import WindowProvider from '../window/WindowProvider';

function getEvent(eventName, eventData) {
  return new CustomEvent(eventName, { detail: eventData });
}

/**
 * @param config.data The event payload.
 * @param config.target The target element on which the event is dispatched on
 */
function dispatch(eventName, config = {}) {
  const defaultConfig = { target: WindowProvider };
  const eventConfig = { ...defaultConfig, ...config };
  const customEvent = getEvent(eventName, eventConfig.data);

  eventConfig.target.dispatchEvent(customEvent);
}

/**
 * @param config.callback The callback that will be called.
 * @param config.target The target element on which the event will be listening on
 */
function addListener(eventName, config = {}) {
  if (typeof config.callback !== 'function') {
    // eslint-disable-next-line fp/no-throw
    throw new Error('Callback function must be specified');
  }

  const defaultConfig = { target: WindowProvider };
  const eventConfig = { ...defaultConfig, ...config };

  eventConfig.target.addEventListener(eventName, eventConfig.callback);
}

/**
 * @param config.callback The callback that will be removed.
 * @param config.target The target element on which the event is listening on
 */
function removeListener(eventName, config = {}) {
  if (typeof config.callback !== 'function') {
    // eslint-disable-next-line fp/no-throw
    throw new Error('Callback function must be specified');
  }

  const defaultConfig = { target: WindowProvider };
  const eventConfig = { ...defaultConfig, ...config };

  eventConfig.target.removeEventListener(eventName, eventConfig.callback);
}

const EventService = {
  addListener,
  dispatch,
  removeListener,
};

export default EventService;

function isIe10orOlder() {
  // https://msdn.microsoft.com/library/8ka90k2e(v=vs.94).aspx
  // eslint-disable-next-line spaced-comment
  /*@cc_on @*/
  // eslint-disable-next-line spaced-comment
  /*@
      return true;
  @*/
  return false;
}

function isIe11OrOlder() {
  return !!navigator.userAgent.match(/Trident\/7\./) || isIe10orOlder();
}

function isEdge() {
  return /Edge/.test(navigator.userAgent);
}

const BrowserDetectionService = {
  isEdge,
  isIe11OrOlder,
};

export default BrowserDetectionService;
